import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import * as styles from "../css/header.module.css"

const Header = ({ back }) => (
  <div className={styles.container}>
    <div className={back ? styles.gWrap : styles.wrap}>
      <Link to="/">
        <StaticImage
          alt="Cian Moore"
          src="../img/home/1.png"
          className={styles.img}
          imgStyle={{ width: "100%", height: "inherit" }}
          loading="eager"
          placeholder="none"
        />
      </Link>
    </div>

    <div className={back ? styles.gBackWrap : styles.backWrap}>
      <Link to="../">
        <StaticImage
          alt="Back to Gallery"
          src="../img/back.png"
          className={styles.backImg}
          draggable={false}
          loading="eager"
          placeholder="none"
        />
      </Link>
    </div>
  </div>
)

Header.propTypes = {
  back: PropTypes.bool.isRequired,
}

export default Header
