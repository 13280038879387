import React from "react"
import PropTypes from "prop-types"
import Header from "./header"
import "../css/layout.css"

const Layout = ({ children, back = false }) => (
  <div style={{ textAlign: "center" }}>
    <Header back={back} />
    <main>{children}</main>
  </div>
)

Layout.defaultProps = {
  back: false,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  back: PropTypes.bool,
}

export default Layout
