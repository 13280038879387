import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Div100vh from "react-div-100vh"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as styles from "../css/about.module.css"

const AboutPage = () => (
  <Layout>
    <SEO title="ABOUT" />
    <Div100vh>
      <div className={styles.container}>
        <div className={styles.wrap}>
          <StaticImage
            src="../img/about.png"
            draggable={false}
            placeholder="none"
            loading="eager"
            width={650}
            quality={100}
          />
        </div>
      </div>
    </Div100vh>
    <div className={styles.pc}>
      <a href="https://peter.digital">
        <StaticImage
          src="../img/pc.png"
          className={styles.pcImage}
          draggable={false}
          placeholder="none"
          loading="eager"
          height={75}
          width={75}
        />
      </a>
    </div>
  </Layout>
)

export default AboutPage
